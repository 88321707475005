import img1 from '../../images/acoustic-duo01.jpg'
import img2 from '../../images/acoustic-duo02.jpg'
import './index.scss'

export const AcousticDuo = () => {
	return (
		<>
			{/* <div className='place-holder'></div> */}
			{/* <div className='music-page-header-section'>
				<img src={headerImg} alt='Eliz Baya' />
				<button className='arrow-btn'>
					<a href='#paragraph' className='arrow-btn'>
						<ArrowDown fill='white' className='svg-icon arrow-down-icon' />
					</a>
				</button>
			</div> */}

			<div className='music-page-paragraphs-section' id='paragraph'>
				<div className='acoustic-duo-group'>
					<img src={img1} alt='Eliz Baya - acoustic duo' />
					<img src={img2} alt='Eliz Baya - acoustic duo' className='off-mobile' />
				</div>
				<div className='paragraphs-section'>
					<h1>The 2 Of Us</h1>
					<p>
						<strong>The 2 Of Us </strong>is a vibrant acoustic duo entertaining the crowds
						with pop and rock tunes that make everyone dance and sing.{' '}
						<strong>Eliz Baya</strong> and <strong>Nelson Aguilar</strong> are performing
						on a regular basis across London at the venues such as O'Neills, The Cambridge
						in Soho and many more.
					</p>
					<h2>Contact Us</h2>
					<div className='social-group'>
						<a href='mailto:elizbayaofficial@gmail.com'>Email Us</a>
						<a href='https://www.instagram.com/elizbaya/'>Follow Eliz Baya</a>
						<a href='https://www.facebook.com/nelsocomusic'>Follow Nelson Aguilar</a>
					</div>
				</div>
				<div className='acoustic-duo-group'>
					<img src={img2} alt='Eliz Baya - acoustic duo' className='on-mobile' />
				</div>
			</div>
		</>
	)
}
