import React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import './ClientsAndVenues.scss'

import client1 from '../../../images/clients/nobu.png'
import client2 from '../../../images/clients/anthracite.png'
import client3 from '../../../images/clients/gnh0.png'
import client4 from '../../../images/clients/yacht.png'
import client5 from '../../../images/clients/andaz.png'
import client6 from '../../../images/clients/jazz after dark.png'
import client7 from '../../../images/clients/Hampton-Court-Palace-Logo-Prestigious-Venues-400x400px.png'
import client8 from '../../../images/clients/rich-mix-logo-2x_0.png'
import client9 from '../../../images/clients/pizzaExpressLive.jpg'
import client10 from '../../../images/clients/golf.jpg'
import client11 from '../../../images/clients/American-langugage-center.png'
import client12 from '../../../images/clients/The-Grove-Logo-Prestigious-Venues.png'

const clients = [
	{ src: client1, alt: 'Nobu London' },
	{ src: client9, alt: 'Pizza Express Live music' },
	{ src: client2, alt: 'anthracite london martiny bar', bigger: true },
	{ src: client3, alt: 'grand northen hotel london bar restaurant' },
	{ src: client4, alt: 'sunborn yacht hotels london' },
	{ src: client5, alt: 'andaz hotel london liverpool street', bigger: true },
	{ src: client10, alt: 'dyrham park country golf club london' },
	{ src: client12, alt: 'the grove london', bigger: true },
	{ src: client6, alt: 'jazz after dark london', bigger: true },
	{ src: client7, alt: 'hampton court palace london', bigger: true },
	{ src: client8, alt: 'rich mix london music gig' },
	{ src: client11, alt: 'american langugage center' },
]

const ClientsAndVenues = () => {
	return (
		<div className='clients-venues-container'>
			<h2>Previous Clients And Venues</h2>

			<ul className='clients-venues-container-logos'>
				{clients.map((item, index) => (
					<li key={index}>
						<img
							src={`${item.src}`}
							alt={item.alt}
							style={item.bigger ? { width: '11rem' } : { width: '7rem' }}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}

export default ClientsAndVenues
