import AboutImg from '../../../images/patricia-bajakova-pub-gig-about.jpg'
import './about.scss'

export const About = () => {
	return (
		<div className='about-section'>
			<div className='image-container'>
				<img src={AboutImg} alt='Eliz Baya, Patricia Bajakova' />
			</div>
			<div className='text-container'>
				<h2>About Me</h2>
				<p>
					Originally born and raised in Slovakia, Eliz decided to leave her beautiful
					country and chase up her dream career in England, currently based in London. As
					a professional jazz/soul/pop singer experienced in the entertainment industry
					for many years now she is also an aspiring singer/ songwriter creating her own
					music. She is also a professionally trained actor at Drama Studio London with
					Ba. Hons degree in Performing arts from CCCU.
				</p>
			</div>
		</div>
	)
}
