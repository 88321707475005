import './index.scss'
import logo from '../../images/London-Daily-News.png'

export const Reviews = () => {
	const reviewList = [
		{
			img: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Beijing_bouddhist_monk_2009_IMG_1486.JPG',
			link: 'https://www.londondaily.news/whats-on-this-september/',
			title: 'Jazz & Negroni Night at The Great Northern Hotel, King’s Cross',
			quotes:
				'The Great Northern Hotel – a stylish hotspot in the heart of King’s Cross is offering live music from renowned artists on the London jazz scene. The buzzy, Art Deco-inspired GNH Bar and Terrace is hosting the Rocca Brother’s Trio, alongside a delicious new signature negroni menu. The trio’s dynamic sets will include both jazz classics and hits from music legends. Against the backdrop of silky-smooth vocals and toe-tapping jazz, dance the night away whilst indulging in carefully crafted negroni cocktails from Bar Manager Diogo Felico.',
		},
	]
	return (
		<div className='reviews-container'>
			<h2>Reviews</h2>
			<div>
				{reviewList.map((review, index) => (
					<div className='review-item' key={index}>
						<blockquote cite={review.link}>
							<h3>{review.title}</h3>
							{review.quotes}
						</blockquote>
						<img src={logo} alt='' />
					</div>
				))}
			</div>
		</div>
	)
}
