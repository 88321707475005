import './index.scss'

export const VideoGallery = () => {
	const videos = [
		{
			src: 'https://www.youtube.com/embed/8toW7esT1G0',
			width: 485,
			height: 300,
			title: 'Trio Showreel',
		},
		{
			src: 'https://www.youtube.com/embed/ZyjOJY8VSno',
			width: 485,
			height: 300,
			title: 'Amy Winehouse - Back to Black ( The Rocca Brothers feat. Eliz Baya )',
		},
		{
			src: 'https://player.vimeo.com/video/761611038?h=e51d7de54e',
			width: 485,
			height: 300,
			title: 'Eliz Baya - can take my eyes off you (cover)',
		},
		{
			src: 'https://www.youtube.com/embed//N4zYYJGZ0tA',
			width: 485,
			height: 300,
			title:
				'Redbone // Crazy - Cover by The Rocca Brothers Quintet feat. Patricia Bajakova',
		},
		{
			src: 'https://player.vimeo.com/video/761603562?h=db851af462',
			width: 485,
			height: 300,
			title: 'Eliz Baya - Cover - Vimeo 2',
		},

		{
			src: 'https://www.youtube.com/embed/VyOFDzdqzzA',
			width: 485,
			height: 300,
			title:
				'Redbone - Childish Gambino ( Cover by The Rocca Brothers Trio feat.Patricia Bajakova)',
		},
		
	]
	return (
		<div className='video-gallery-container'>
			{videos.map((video, index) => (
				<iframe
				frameBorder={0}
				title={video.title}
				key={index}
				src={video.src}
				width={video.width}
				height={video.height}
				/>
				))}
		</div>
	)
}
