import ClientsAndVenues from '../../common/Footer/Sections/ClientsAndVenues'
import { PhotoGallery } from '../../common/PhotoGallery'
import { Reviews } from '../../common/Reviews'
import { UpcomingEvents } from '../../common/UpcomingEvents'
import { VideoGallery } from '../../common/VideoGallery'
import headerImg from '../../images/eliz-baya-header.gif'
import { ArrowDown } from '../../images/icons/socialMedia'
import './index.scss'

const events = [
	{
		date: 'Wed, 28.2 & 13.3 & 27.3 & 10.4 & 1.5 & 15.5 & 29.5 at 7pm',
		location: 'BeBeMe Wine Bar, Mercato Mayfair',
		type: 'Jazz Nights',
		googleMap: 'https://www.google.com/maps?q=St+Mark’s,+Mercato,+N+Audley+St,+London',
	},
	{
		date: 'Tue, 5.3 & 2.4 & 7.5 & 4.6 & 2.7 at 7pm',
		location: 'GNH Bar, Kings Cross',
		type: 'Jazz & Negroni Night',
		googleMap: 'https://www.google.com/maps?q=GNH+Bar,+Kings+cross,+london',
	},
	{
		date: 'Sat, 2.3 at 8pm',
		location: "O'Neills, Carnaby Street",
		type: 'Live Music',
		googleMap: "https://www.google.com/maps?q=O'Neills+Carnaby+Street,+london",
	},
	{
		date: 'Sat, 9.3 at 9.30pm',
		location: "O'Neills, Kings Cross",
		type: 'Live Music',
		googleMap: "https://www.google.com/maps?q=O'Neills+ Kings+Cross,+london",
	},
	{
		date: 'Sun, 17.3 at 6pm',
		location: "O'Neills, Kingston",
		type: 'Live Music on St Patrick’s day',
		googleMap: "https://www.google.com/maps?q=O'Neills+Kingston,+london",
	},
]

export const Music = () => {
	return (
		<>
			{/* <div className='place-holder'></div> */}
			{/* <div className='music-page-header-section'> */}
			{/* <img src={headerImg} /> */}
			{/* <button className='arrow-btn'> */}
			{/* <a href='#paragraph' className='arrow-btn'> */}
			{/* <ArrowDown fill='white' className='svg-icon arrow-down-icon' /> */}
			{/* </a> */}
			{/* </button> */}
			{/* </div> */}
			<div className='music-page-paragraphs-section' id='paragraph'>
				<p>
					Eliz has been providing entertainment with her silky smooth vocals at events,
					functions, parties and music venues around the world as part of duo, trio and
					quintet as well as solo.
				</p>
				<p>
					For many years now, Eliz has been singing and working with unforgettable Rocca
					Brothers. They often perform at private events and serve as the perfect act for
					either providing background music or bringing a dazzling central focused
					performance to any kind of event and venue.
				</p>
				<p>
					Specialising in covers, their sets can include well known jazz classics
					alongside hits from the likes of Stevie Wonder, Amy Winehouse with some modern
					pop favourites.Also boasting a huge repertoire with an eclectic mix of jazz &
					blues classics as well as soul favourites.
				</p>
				<p>
					Eliz Baya has recently joined acoustic duo singing pop/rock to packed London
					venues such as O'Neills, The Cambridge in Soho and many more to come.
				</p>
			</div>
			<UpcomingEvents events={events} />
			<PhotoGallery />
			<Reviews />
			<VideoGallery />
			<ClientsAndVenues />
		</>
	)
}
