import React, { useState } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import './index.scss'

import photo0 from '../../images/photo-gallery/patricia-bajakova-eliz-baya.jpg'
import photo1 from '../../images/photo-gallery/jazz-after-dark-eliz-baya.jpg'
import photo2 from '../../images/photo-gallery/eliz-baya-gig.jpg'
import photo3 from '../../images/photo-gallery/patricia-bajakova-pub-gig.jpg'
import photo4 from '../../images/photo-gallery/patricia-bajakova-jazz-band.jpg'
import photo5 from '../../images/photo-gallery/Patricia-Bajakova.jpg'
import photo6 from '../../images/photo-gallery/jazz-gig-patricia-bajakova.jpg'
import photo7 from '../../images/photo-gallery/pb-trio.jpeg'
import { ModalComponent } from './Modal'

export interface ImagesProps {
	src: string
	alt: string
	loading: 'lazy' | 'eager' | undefined
}

export const PhotoGallery = () => {
	const [index, setIndex] = React.useState(0)
	const [open, setOpen] = React.useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)

	const images: Array<ImagesProps> = [
		{
			src: photo2,
			loading: 'lazy',
			alt: 'eliz-baya-gig',
		},
		{
			src: photo3,
			loading: 'lazy',
			alt: 'patricia-bajakova-pub-gig',
		},

		{
			src: photo6,
			loading: 'lazy',
			alt: 'jazz-gig-patricia-bajakova',
		},

		{
			src: photo0,
			loading: 'lazy',
			alt: 'patricia-bajakova-eliz-baya',
		},
		{
			src: photo7,
			loading: 'lazy',
			alt: 'jazz trio',
		},

		{
			src: photo4,
			loading: 'lazy',
			alt: 'patricia-bajakova-jazz-band',
		},
		{
			src: photo1,
			loading: 'lazy',
			alt: 'jazz-after-dark-eliz-baya',
		},

		{
			src: photo5,
			loading: 'lazy',
			alt: 'eliz baya',
		},
	]
	const showImageHandle = (indexImage: number) => {
		setIndex(indexImage)
		handleOpen()
	}

	return (
		<div className='photo-gallery-container'>
			<ImageList
				variant='masonry'
				cols={1}
				sx={{ display: { sm: 'none', xs: 'block' }, padding: '2rem' }}
				gap={8}
			>
				{images.map((item, index) => (
					<ImageListItem key={index}>
						<img
							src={`${item.src}`}
							alt={item.alt}
							// onClick={() => showImageHandle(index)}
						/>
					</ImageListItem>
				))}
			</ImageList>
			<ImageList
				variant='masonry'
				sx={{ display: { sm: 'block', md: 'block', xs: 'none' }, padding: '2rem' }}
				gap={8}
			>
				{images.map((item, index) => (
					<ImageListItem key={index}>
						<img
							src={`${item.src}`}
							alt={item.alt}
							// onClick={() => showImageHandle(index)}
						/>
					</ImageListItem>
				))}
			</ImageList>
			{/* <ModalComponent
				images={images}
				imageIndex={index}
				open={open}
				handleClose={handleClose}
			/> */}
		</div>
	)
}
