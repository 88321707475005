import { About } from './Sections/About'
import './index.scss'

const Home = () => {
	return (
		<>
			<header className='home-page'>
				<h1 className='title'>Eliz Baya</h1>
				{/* <h2 className='sub-title'>Actor &#38; Singer</h2> */}
			</header>
			<About />
		</>
	)
}
export { Home }
