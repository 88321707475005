import './index.scss'

interface UpcomingEventsProps {
	events: Array<{ date: string; location: string; type: string; googleMap?: string }>
}

export const UpcomingEvents = ({ events }: UpcomingEventsProps) => {
	return (
		<div className='upcoming-events-container'>
			{events ? (
				<>
					<h2>Upcoming live performances</h2>
					<div className='upcoming-evens-items-container'>
						{events.map((event: any) => (
							<div className='upcoming-evens-item'>
								<ul>
									<li>{event.date}</li>
									<li>{event.location}</li>
									<li>{event.type}</li>
								</ul>

								{event.googleMap && (
									<a href={event.googleMap} target='_blank' className='event-item-link'>
										Show map
									</a>
								)}
							</div>
						))}
					</div>
				</>
			) : (
				<h2>No events</h2>
			)}
		</div>
	)
}
