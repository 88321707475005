import './index.scss'
import {
	FacebookIcon,
	InstagramIcon,
	MenuIcon,
	SpotifyIcon,
	YoutubeIcon,
} from '../../images/icons/socialMedia.js'
import { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'
import { link } from 'fs'

const menuList = [
	{ name: 'About', src: '..' },
	{ name: 'Music', src: 'music' },
]
const socials = [
	{
		link: 'https://www.instagram.com/elizbaya/',
		name: 'Instagram',
		icon: <InstagramIcon fill='black' className='svg-icon' />,
	},
	{
		link: 'https://www.facebook.com/elizbayaofficial',
		name: 'Facebook',
		icon: <FacebookIcon fill='black' className='svg-icon' />,
	},
	{
		link: 'https://www.youtube.com/channel/UCq20nwCI0hOY3h95UQzDDAQ',
		name: 'Youtube',
		icon: <YoutubeIcon fill='black' className='svg-icon' />,
	},
	{
		link: 'https://open.spotify.com/artist/57FSS0lsZrIwZofUV2Y3PQ?si=SAW3u59ER7q3NVpQYs1cMQ',
		name: 'Spotify',
		icon: <SpotifyIcon fill='black' className='svg-icon' />,
	},
]
export const MenuItems: React.FC = (): JSX.Element => {
	return (
		<ul className='menu-items'>
			{menuList.map((item, index) => (
				<li key={index}></li>
			))}
			<li>
				<a href='https://fanlink.to/pZzq' rel='noreferrer' target='_blank'>
					Singer/Songwriter
				</a>
			</li>
			<li>
				<a href='#contact'>Contact</a>
			</li>
		</ul>
	)
}
export const Navbar = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const goToBottom = () => {
		window.scrollTo(0, document.body.scrollHeight)
	}
	const goToTop = () => {
		window.scrollTo(0, 0)
	}

	const toggleOpenMenu = () => {
		if (isOpen === false) {
			setIsOpen(true)
			document && document?.body?.classList.add('stop-scrolling')
		} else {
			setIsOpen(false)
			document && document?.body?.classList.remove('stop-scrolling')
		}
	}

	return (
		<>
			<nav className='nav-mobile'>
				<ul className='social-media'>
					{socials.map((item) => (
						<li>
							<a href={item.link} target='_blank' aria-label={item.name}>
								{item.icon}
							</a>
						</li>
					))}
				</ul>
				<div className='burger-menu'>
					<button onClick={() => toggleOpenMenu()} aria-label='menu'>
						<MenuIcon fill='black' className='svg-menu-icon' />
					</button>
				</div>
			</nav>
			<nav className='nav-desktop'>
				<ul className='social-media'>
					{socials.map((item) => (
						<li>
							<a href={item.link} target='_blank'>
								{item.icon}
							</a>
						</li>
					))}
				</ul>
				<List className='menu-items'>
					{[
						{ text: 'About', src: '..' },
						{ text: 'Music', src: 'music' },
						{ text: 'Singer/Songwriter', src: 'https://fanlink.to/pZzq' },
						{ text: 'Contact', src: '#contact' },
					].map((menu, index) => (
						<ListItem key={index} disablePadding>
							<ListItemButton
								style={{ backgroundColor: 'transparent' }}
								onClick={() => (menu.text === 'Contact' ? goToBottom() : goToTop())}
							>
								<ListItemText>
									{menu.text === 'Singer/Songwriter' ? (
										<a
											href={menu.src}
											rel='noreferrer'
											target='_blank'
											style={{ textDecoration: 'none', color: 'black' }}
										>
											{menu.text}
										</a>
									) : (
										<Link
											to={menu.src}
											style={{ textDecoration: 'none', color: 'black' }}
										>
											{menu.text}
										</Link>
									)}
								</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
				{/* <ul className='menu-items'>
					{menuList.map((item, index) => (
						<li key={index}></li>
					))}
					<li>
						<a href='https://official.elizbaya.com' target='_blank' onClick={(e) => console.log(e.target)}>
							Singer/Songwriter
						</a>
					</li>
					<li>
						<a href='#contact' onClick={(e) => console.log(e.target)}>
							Contact
						</a>
					</li>
				</ul> */}
			</nav>
		</>
	)
}
