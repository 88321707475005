import {
	FacebookIcon,
	InstagramIcon,
	MailIcon,
	PhoneIcon,
	TwitterIcon,
	WebsiteIcon,
	YoutubeIcon,
} from '../../images/icons/socialMedia'
import { Contact } from './Sections/Contact'
import './index.scss'
import { Link } from 'react-router-dom'
export const Footer = () => {
	return (
		<>
			<footer>
				<div id='contact'>
					<div className='agent-container'>
						<h3>Acting enquiry</h3>
						<ul className='agent-list'>
							<li>Agent:</li>
							<li>Will &#38; Lydia</li>
							<li>VICTORIOUS MANAGEMENT</li>
							<li>
								<a href='https://www.victoriousmanagement.com'>
									<WebsiteIcon fill='black' className='svg-icon' />{' '}
									https://www.victoriousmanagement.com
								</a>
							</li>
							<li>
								<a href='tel:004402070960722'>
									<PhoneIcon fill='black' className='svg-icon' />
									020 7096 0722
								</a>
							</li>
							<li>
								<a href='mailto:agent@victoriousmanagement.com'>
									<MailIcon fill='black' className='svg-icon' />
									agent@victoriousmanagement.com
								</a>
							</li>
						</ul>
					</div>
					{/* Singing contact */}
					<div className='singing-container'>
						<h3>Singing enquiry</h3>
						<ul className='agent-list'>
							<li>
								<a href='https://api.whatsapp.com/send?phone=04407510889758'>
									<PhoneIcon fill='black' className='svg-icon' />
									Send WhatsApp Message
								</a>
							</li>
							<li>
								<a href='mailto:pbajakova@gmail.comsubject=Singing enquiry(elizbaya)'>
									<MailIcon fill='black' className='svg-icon' />
									pbajakova@gmail.com
								</a>
							</li>
						</ul>
					</div>
				</div>
				{/* <Contact /> */}
			</footer>
		</>
	)
}
