import * as React from 'react'
import '../Navbar/index.scss'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {
	CloseIcon,
	FacebookIcon,
	InstagramIcon,
	MenuIcon,
	SpotifyIcon,
	TwitterIcon,
	YoutubeIcon,
} from '../../images/icons/socialMedia'
import { Link } from 'react-router-dom'

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
}

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}))

const socials = [
	{
		link: 'https://www.instagram.com/elizbaya/',
		icon: <InstagramIcon fill='black' className='svg-icon' />,
	},
	{
		link: 'https://www.facebook.com/elizbayaofficial',
		icon: <FacebookIcon fill='black' className='svg-icon' />,
	},
	{
		link: 'https://www.youtube.com/channel/UCq20nwCI0hOY3h95UQzDDAQ',
		icon: <YoutubeIcon fill='black' className='svg-icon' />,
	},
	{
		link: 'https://open.spotify.com/artist/57FSS0lsZrIwZofUV2Y3PQ?si=SAW3u59ER7q3NVpQYs1cMQ',
		icon: <SpotifyIcon fill='black' className='svg-icon' />,
	},
]

export const ResponsiveAppBar = ({ open, setOpen, drawerWidth }: any) => {
	const theme = useTheme()
	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== 'open',
	})<AppBarProps>(({ theme, open }) => ({
		backgroundColor: 'white',
		color: 'black',
		boxShadow: 'none',
		[theme.breakpoints.up(840)]: {
			display: 'none',
		},
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: `${drawerWidth}px`,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}))

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
		window.scrollTo(0, 0)
	}
	const goToBottom = () => {
		window.scrollTo(0, document.body.scrollHeight)
	}

	return (
		<>
			<AppBar position='fixed' open={open}>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={handleDrawerOpen}
						edge='start'
						sx={{ mr: 2, ...(open && { display: 'none' }) }}
					>
						<MenuIcon fill='black' className='svg-icon' />
					</IconButton>
					<Typography variant='h6' noWrap component='div'>
						<ul className='social-media'>
							{socials.map((item) => (
								<li>
									<a href={item.link} target='_blank' rel='noreferrer'>
										{item.icon}
									</a>
								</li>
							))}
						</ul>
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant='persistent'
				anchor='left'
				open={open}
			>
				<DrawerHeader>
					<IconButton onClick={() => setOpen(false)}>
						<CloseIcon fill='black' className='svg-icon' />
					</IconButton>
				</DrawerHeader>
				<List>
					{[
						{ text: 'About', src: '/' },
						{ text: 'Music', src: '/music' },
						{ text: 'Singer/Songwriter', src: 'https://fanlink.to/pZzq' },
						{ text: 'Contact', src: '#contact' },
					].map((menu, index) => (
						<ListItem key={index} disablePadding>
							<ListItemButton
								onClick={() => (menu.text === 'Contact' ? goToBottom() : null)}
							>
								<ListItemText onClick={handleDrawerClose}>
									{menu.text === 'Singer/Songwriter' ? (
										<a
											href={menu.src}
											style={{ textDecoration: 'none', color: 'black' }}
											target='_blank'
											rel='noreferrer'
										>
											{menu.text}
										</a>
									) : (
										<Link
											to={menu.src}
											style={{ textDecoration: 'none', color: 'black' }}
										>
											{menu.text}
										</Link>
									)}
								</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
		</>
	)
}
