import { Routes, Route, useLocation } from 'react-router-dom'
import { Home } from './pages/Home'
import { Navbar } from './common/Navbar'
import { Footer } from './common/Footer'
import { Music } from './pages/Music'
import { ResponsiveAppBar } from './common/NavbarMUI'
import { styled, useTheme } from '@mui/material/styles'
import React, { useEffect } from 'react'
import NotFound from './pages/NotFound/NotFound'
import { AcousticDuo } from './pages/AcousticDuo'
const drawerWidth = 230
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
	open?: boolean
}>(({ theme, open }) => ({
	flexGrow: 1,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
	height: '100%',
	overflow: 'hidden',
}))
const App = () => {
	const { pathname } = useLocation()
	const [open, setOpen] = React.useState(false)
	useEffect(() => {
		open && document?.body?.classList.add('stop-scrolling')

		return () => {
			document?.body?.classList.remove('stop-scrolling')
		}
	}, [open])

	return (
		<>
			{pathname !== '/acoustic-duo' && (
				<ResponsiveAppBar open={open} setOpen={setOpen} drawerWidth={drawerWidth} />
			)}{' '}
			{pathname !== '/acoustic-duo' && <Navbar />}
			<Main open={open}>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/music' element={<Music />} />
					<Route path='/acoustic-duo' element={<AcousticDuo />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Main>
			{pathname !== '/acoustic-duo' && <Footer />}
		</>
	)
}

export default App
